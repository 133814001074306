import { useEffect } from 'react';

/**
 * Custom hook to block navigation transitions using a callback.
 *
 * @param callback Function to determine whether navigation is allowed.
 * If it returns `false`, navigation is blocked.
 */
export const useNavigateBlock = (callback: (location: Location, action: string) => false | void) => {
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      const location = window.location; // Current window location
      const action = 'POP'; // Navigation triggered by browser back/forward
      const allowNavigation = callback(location, action);

      if (allowNavigation === false) {
        event.preventDefault();
        // Optionally, push back to current URL to keep the user on the page
        window.history.pushState(null, '', window.location.href);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [callback]);
};
