import { CEBrandColors, CEShadeColors } from '@hallmark/web.styles.colors';

export const basicTextColors: string[] = [
  CEShadeColors.DarkBlack,
  CEShadeColors.BrightBlue,
  CEShadeColors.BrightGreen,
  CEShadeColors.VividRed,
  CEShadeColors.BrightMagenta,
  CEShadeColors.LightPurple,
];

// Dodajemo sve boje iz CEShadeColors u extendedTextColors
export const extendedTextColors: string[] = [
  CEShadeColors.DarkBlack,
  CEShadeColors.CharcoalGray,
  CEShadeColors.MediumGray,
  CEShadeColors.LightGray,
  CEShadeColors.VeryLightGray,
  CEShadeColors.PureWhite,

  CEShadeColors.DarkNavyBlue,
  CEShadeColors.DeepTeal,
  CEShadeColors.BrightBlue,
  CEShadeColors.SkyBlue,
  CEShadeColors.SoftMint,
  CEShadeColors.MutedTeal,

  CEShadeColors.DeepForestGreen,
  CEShadeColors.DarkJungleGreen,
  CEShadeColors.BrightGreen,
  CEShadeColors.LightGreen,
  CEShadeColors.MutedGreen,
  CEShadeColors.LightLime,

  CEShadeColors.SoftGoldenrod,
  CEShadeColors.BrightYellow,
  CEShadeColors.LightYellow,
  CEShadeColors.PaleYellow,
  CEShadeColors.LightPaleYellow,
  CEShadeColors.PalePink,

  CEShadeColors.WarmOrange,
  CEShadeColors.WarmSunsetOrange,
  CEShadeColors.SoftOrange,
  CEShadeColors.Peach,
  CEShadeColors.LightPeach,
  CEShadeColors.WarmBlush,

  CEShadeColors.DarkCrimson,
  CEShadeColors.VividRed,
  CEShadeColors.SoftRed,
  CEShadeColors.BrightRed,
  CEShadeColors.MutedRose,
  CEShadeColors.SoftRose,

  CEShadeColors.DeepBurgundy,
  CEShadeColors.DeepRose,
  CEShadeColors.BrightPink,
  CEShadeColors.BrightMagenta,
  CEShadeColors.LightRose,
  CEBrandColors.Pink10,

  CEShadeColors.DarkPlum,
  CEShadeColors.DarkAmethyst,
  CEShadeColors.LightPurple,
  CEShadeColors.Lavender,
  CEShadeColors.SoftLavender,
  CEShadeColors.LightLilac,

  CEShadeColors.WarmBrown,
  CEShadeColors.DeepOliveBrown,
  CEShadeColors.MutedBrown,
  CEShadeColors.SoftBrown,
  CEShadeColors.DeepTan,
  CEShadeColors.MutedTan,
];
