import React, { useEffect } from 'react';
import { fabric } from 'fabric';
import {
  useAppContext,
  setIsDeleteConfirmationDialogOpen,
  setIsSizeDrawerOpen,
  setIsRotationDrawerOpen,
  setIsScaleDrawerOpen,
} from '../../context/app-context';
import { useInitializationDataContext } from '../../context/data-context';
import { FabricObject, FabricTextBox } from '../../global-types';
import { CardType } from '../../global-types/card';
import { DialogType } from '../../global-types/dialog';
import { config } from '../../regional-config';
import { CanvasDataTypes, helperSettingsConfig, isImage, isTextbox } from '../../utils';
import { renderSizeTextIcon } from '../../utils';
import { CanvasCustomControlsProps, CustomControlsIcons, CustomControlOptions } from './canvas-custom-config-types';
import { renderCustomCroppingControl } from './utils/render-custom-cropping-control';

export const CanvasCustomConfig = ({ children }: CanvasCustomControlsProps) => {
  const { appDispatch } = useAppContext();
  const { scalingFactor } = helperSettingsConfig;
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const windowFabric = window.fabric;

  const renderIcon = (icon: HTMLImageElement) => {
    return function renderIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: unknown,
      fabricObject: fabric.Object,
    ): void {
      const size = 32;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0));
      ctx.drawImage(icon, -size / scalingFactor, -size / scalingFactor, size, size);
      ctx.restore();
    };
  };

  const drawRotateControl = (ctx: CanvasRenderingContext2D, options: CustomControlOptions) => {
    const { left, top, size, angle } = options;
    const sideSize = size / scalingFactor;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(angle));
    ctx.rect(-sideSize / scalingFactor, -sideSize / scalingFactor, sideSize, sideSize);
    ctx.stroke();
    ctx.fill();
    ctx.restore();
  };

  const drawMiddleControl = (ctx: CanvasRenderingContext2D, width: number, length: number) => {
    ctx.rect(-width / 2, -length / 2, width, length);
    ctx.stroke();
    ctx.fill();
  };

  const drawCorner = (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    cornerSize: number,
    lineWidth: number,
  ) => {
    ctx.beginPath();
    ctx.moveTo(left, top);
    ctx.lineTo(left + cornerSize, top);
    ctx.lineTo(left + cornerSize, top + lineWidth);
    ctx.lineTo(left + lineWidth, top + lineWidth);
    ctx.lineTo(left + lineWidth, top + cornerSize);
    ctx.lineTo(left, top + cornerSize);
    ctx.lineTo(left, top);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
  };

  const renderCornerControl = (controlName: string, ctx: CanvasRenderingContext2D, options: CustomControlOptions) => {
    const { left, top, size, angle } = options;
    const lineWidth = 3;
    switch (controlName) {
      case 'tl':
        ctx.save();

        ctx.translate(left, top);
        // rotate drawing canvas so control is aligned correctly with the object (cropping-rect)
        ctx.rotate(fabric.util.degreesToRadians(angle));
        drawCorner(ctx, 0, 0, size, lineWidth);
        ctx.restore();
        break;
      case 'tr':
        ctx.save();
        ctx.translate(left, top);
        // Instead of drawing each particular corner, draw an initial corner (top-left) and rotate as needed,
        // we also need to account for the object's angle when rotating
        ctx.rotate(fabric.util.degreesToRadians(90 + angle));
        drawCorner(ctx, 0, 0, size, lineWidth);
        ctx.restore();
        break;
      case 'bl':
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(-90 + angle));
        drawCorner(ctx, 0, 0, size, lineWidth);
        ctx.restore();
        break;
      case 'br':
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(180 + angle));
        drawCorner(ctx, 0, 0, size, lineWidth);
        ctx.restore();
        break;
      default:
        break;
    }
  };

  const renderMiddleControl = (controlName: string, ctx: CanvasRenderingContext2D, options: CustomControlOptions) => {
    const { left, top, size, angle } = options;
    const rectWidth = 3;
    switch (controlName) {
      case 'ml':
      case 'mr':
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(angle));
        drawMiddleControl(ctx, rectWidth, size);
        ctx.restore();
        break;
      case 'mt':
      case 'mb':
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(90 + angle));
        drawMiddleControl(ctx, rectWidth, size);
        ctx.restore();
        break;
      default:
        break;
    }
  };

  const renderCustomControl = (
    controlName: string,
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    fabricObject: FabricObject,
  ) => {
    const initialSize = 28;
    let scaledSize = initialSize;
    const { scaleX = 0, scaleY = 0, angle = 0 } = fabricObject;

    if (scaleX < 1 || scaleY < 1) {
      scaledSize = Math.max(Math.min(initialSize * scaleX, initialSize * scaleY), 10);
      fabricObject.cornerSize = scaledSize;
    }

    const options = {
      left: left,
      top: top,
      size: scaledSize,
      angle: angle,
    };

    if (controlName === 'mtr') {
      options.size = initialSize;
      drawRotateControl(ctx, options);
    } else if (controlName.startsWith('m') && controlName !== 'mtr') {
      renderMiddleControl(controlName, ctx, options);
    } else if (!controlName.startsWith('m')) {
      renderCornerControl(controlName, ctx, options);
    }
  };

  const renderCustomControls = (
    icons: Record<string, HTMLImageElement>,
    controlName: string,
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    fabricObject: FabricObject,
    control: fabric.Control,
  ) => {
    const initialSize = 20;
    const sizeMultiplier = 1.8;
    const { angle = 0 } = fabricObject;

    const scaledSize = Math.min(initialSize, 10);

    const options = {
      left,
      top,
      size: scaledSize,
      iconSize: initialSize * sizeMultiplier,
      angle,
    };

    ctx.restore();
    if (isTextbox(fabricObject)) {
      renderControlBasedOnConfig(icons, controlName, ctx, options, control, fabricObject ?? undefined);
    }
    if (isImage(fabricObject)) {
      if (config?.customControls?.renderImageControlIcon) {
        renderControlBasedOnConfig(icons, controlName, ctx, options, control);
      } else {
        renderImageControls(icons, controlName, ctx, options, control);
      }
    }
    if (fabricObject.name === 'cropping-rect') {
      renderCustomCroppingControl(controlName, ctx, left, top, fabricObject);
    }
  };

  const renderImageControls = (
    icons: Record<string, HTMLImageElement>,
    controlName: string,
    ctx: CanvasRenderingContext2D,
    options: CustomControlOptions,
    control: fabric.Control,
  ) => {
    const { left, top, iconSize, size } = options;
    ctx.save();
    ctx.translate(left, top);

    const icon = icons[`${controlName}`];

    if (!icon) {
      return;
    }

    const newSize = size * (config?.customControls?.renderImageIconSize ?? 1);

    const actualSizeRotateIcon = iconSize ?? 0;
    switch (controlName) {
      case 'tr':
      case 'bl':
      case 'tl':
      case 'br':
      case 'mt':
      case 'mb':
      case 'ml':
      case 'mr':
        ctx.drawImage(icon, -newSize / scalingFactor, -newSize / scalingFactor, newSize, newSize);
        break;

      case 'mtr':
        renderSizeTextIcon(actualSizeRotateIcon, control, ctx, icon, scalingFactor);
        break;
    }
    ctx.restore();
  };

  const renderControlBasedOnConfig = (
    icons: Record<string, HTMLImageElement>,
    controlName: string,
    ctx: CanvasRenderingContext2D,
    options: CustomControlOptions,
    control: fabric.Control,
    object?: FabricObject,
  ) => {
    const controlBehavior = config?.customControls?.controlBehaviors?.[controlName as string] ?? '';
    const sanitizedControlName = controlName.replace(/[^a-zA-Z0-9]/g, '');
    const { left, top, size, iconSize } = options;
    const actualSize = iconSize ?? 0;
    const rectangleSize = 16;
    const icon = icons[sanitizedControlName as string];
    const shouldShowResizeControls =
      (object as FabricTextBox)?.CanResizeTextArea ||
      object?.data.type === CanvasDataTypes.UserText ||
      object?.data.type === CanvasDataTypes.Placeholder;
    const shouldShowDeleteControls =
      ((object as FabricTextBox)?.CanDeleteTextArea &&
        (object as FabricTextBox).data.type === CanvasDataTypes.EditableText) ||
      object?.data.type === CanvasDataTypes.Placeholder;

    if (!icon) return;

    ctx.save();
    ctx.translate(left, top);

    switch (controlBehavior) {
      case 'sizeTextIcon':
        if (object && isTextbox(object)) {
          if (
            (controlName === 'deleteControl' && (shouldShowDeleteControls || shouldShowResizeControls)) ||
            controlName === 'resizeControl' ||
            controlName === 'rotateControl'
          ) {
            renderSizeTextIcon(actualSize, control, ctx, icon, scalingFactor);
          }
        } else {
          if (controlName === 'deleteControl' || controlName === 'resizeControl' || controlName === 'rotateControl') {
            renderSizeTextIcon(actualSize, control, ctx, icon, scalingFactor);
          }
        }
        break;
      case 'drawImage':
        if (shouldShowResizeControls) {
          ctx.drawImage(icon, -rectangleSize / 2, -rectangleSize / 2, rectangleSize, rectangleSize);
        }
        break;

      case 'drawImageAdjust':
        if (shouldShowResizeControls) {
          const adjustment = controlName === 'ml' ? -12 : -8;
          ctx.drawImage(icon, adjustment / scalingFactor, -size, size, size + 12);
        }
        break;

      default:
        break;
    }

    ctx.restore();
  };

  const onDeleteObject = (fabricObject: any): boolean => {
    const objectType = fabricObject?.target?.data.type ?? CanvasDataTypes.UserText;
    if (objectType === CanvasDataTypes.StickerImage) {
      setIsDeleteConfirmationDialogOpen(appDispatch, DialogType.Sticker);
    } else if (objectType === CanvasDataTypes.UserImage) {
      setIsDeleteConfirmationDialogOpen(appDispatch, DialogType.Image);
    } else {
      setIsDeleteConfirmationDialogOpen(appDispatch, DialogType.Text);
    }
    return true;
  };
  const onRotateObject = (): boolean => {
    setIsRotationDrawerOpen(appDispatch);
    return true;
  };
  const onResizeObject = (object: string): boolean => {
    if (object === 'textbox') {
      setIsSizeDrawerOpen(appDispatch);
    } else {
      setIsScaleDrawerOpen(appDispatch);
    }
    return true;
  };

  const setCustomControls = (
    { _fabric }: any,
    { deleteIcon, scaleIcon, rectangleIcon, circleIcon, rotateIcon }: CustomControlsIcons,
  ) => {
    _fabric.Object.prototype.drawControls = function (ctx: CanvasRenderingContext2D) {
      let coords: { x: number; y: number };
      this.setCoords();
      this.forEachControl(function (control, key, fabricObject) {
        coords = fabricObject.oCoords[`${key}`];
        let icons;

        const mappingIcon = {
          ce: {
            rotateControl: rotateIcon,
            deleteControl: deleteIcon,
            resizeControl: scaleIcon,
            mtr: rotateIcon,
            tr: rectangleIcon,
            bl: rectangleIcon,
            tl: rectangleIcon,
            br: rectangleIcon,
            mt: rectangleIcon,
            mr: rectangleIcon,
            ml: rectangleIcon,
          },
          us: {
            mr: rectangleIcon,
            ml: rectangleIcon,
            tr: circleIcon,
            bl: circleIcon,
            mtr: rotateIcon,
            deleteControl: deleteIcon,
            tl: circleIcon,
            br: circleIcon,
          },
        };
        switch (config?.customControls?.mappingIcon?.region) {
          case 'ce':
            icons = mappingIcon.ce;
            break;
          case 'us':
            icons = mappingIcon.us;
            break;
          default:
            icons = mappingIcon.us;
            break;
        }
        renderCustomControls(icons, key, ctx, coords.x, coords.y, fabricObject, control);
      });
      ctx.restore();
    };

    _fabric.Object.prototype.set({
      hasBorders: config?.customControls?.hasBorders,
      borderColor: config?.customControls?.borderColor,
      borderScaleFactor: 3,
      borderDashArray: [3, 3],
    });

    _fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
      ...config?.customControls?.possitions?.delete,
      withConnection: true,
      cursorStyle: 'pointer',
      mouseUpHandler: (eventData, fabricObject) => onDeleteObject(fabricObject),
      render: renderIcon(deleteIcon),
      actionName: 'delete',
    });

    _fabric.Textbox.prototype.controls.rotateControl = new fabric.Control({
      ...config?.customControls?.possitions?.rotate,
      cursorStyle: 'pointer',
      mouseUpHandler: onRotateObject,
      actionHandler: (eventData, transformData: fabric.Transform, x, y) => {
        (transformData.originX as 'left' | 'right' | 'center') = 'center';
        (transformData.originY as 'left' | 'right' | 'center') = 'center';
        return fabric.Object.prototype.controls.mtr.actionHandler(eventData, transformData, x, y);
      },
      render: renderIcon(rotateIcon),
    });

    _fabric.Textbox.prototype.controls.resizeControl = new fabric.Control({
      ...config?.customControls?.possitions?.resize,
      cursorStyle: 'pointer',
      mouseUpHandler: () => onResizeObject('textbox'),
      actionHandler: fabric.Object.prototype.controls.br.actionHandler,
      render: renderIcon(scaleIcon),
    });

    _fabric.Textbox.prototype.setControlsVisibility({
      ...config?.customControls?.controlsVisibility,
    });
    delete _fabric.Textbox.prototype.controls.mb;

    _fabric.Image.prototype.setControlsVisibility(config?.customControls?.controlsVisibility);

    _fabric.Image.prototype.controls = {
      deleteControl: new fabric.Control({
        ...config?.customControls?.possitions?.delete,
        withConnection: true,
        cursorStyle: 'pointer',
        mouseUpHandler: (eventData, fabricObject) => onDeleteObject(fabricObject),
        render: renderIcon(deleteIcon),
        actionName: 'delete',
      }),

      rotateControl: new fabric.Control({
        ...config?.customControls?.possitions?.rotate,
        cursorStyle: 'pointer',
        mouseUpHandler: onRotateObject,
        actionHandler: (eventData, transformData: fabric.Transform, x, y) => {
          (transformData.originX as 'left' | 'right' | 'center') = 'center';
          (transformData.originY as 'left' | 'right' | 'center') = 'center';
          return fabric.Object.prototype.controls.mtr.actionHandler(eventData, transformData, x, y);
        },
        render: renderIcon(rotateIcon),
      }),

      resizeControl: new fabric.Control({
        ...config?.customControls?.possitions?.resize,
        cursorStyle: 'pointer',
        mouseUpHandler: () => onResizeObject('image'),
        actionHandler: fabric.Object.prototype.controls.br.actionHandler,
        render: renderIcon(scaleIcon),
      }),
    };
  };

  const setCustomCroppingControls = ({ _fabric }: any) => {
    _fabric.Object.prototype.drawControls = function (
      ctx: CanvasRenderingContext2D,
      styleOverride: { cornerColor?: any; cornerStrokeColor?: any; cornerDashArray?: any },
    ) {
      styleOverride = styleOverride || {};
      ctx.save();
      const retinaScaling = this.canvas.getRetinaScaling();
      let matrix: any[], p: fabric.Point;
      ctx.setTransform(retinaScaling, 0, 0, retinaScaling, 0, 0);
      ctx.strokeStyle = ctx.fillStyle = styleOverride.cornerColor || this.cornerColor;
      if (!this.transparentCorners) {
        ctx.strokeStyle = styleOverride.cornerStrokeColor || this.cornerStrokeColor;
      }
      this._setLineDash(ctx, styleOverride.cornerDashArray || this.cornerDashArray);
      this.setCoords();
      if (this.group) {
        matrix = this.group.calcTransformMatrix();
      }

      this.forEachControl(function (control, key, fabricObject) {
        p = fabricObject.oCoords[`${key}`];
        if (control.getVisibility(fabricObject, key)) {
          if (matrix) {
            p = fabric.util.transformPoint(p, matrix);
          }
          if (fabricObject.name === 'cropping-rect') {
            renderCustomControl(key, ctx, p.x, p.y, fabricObject);
          } else {
            control.render(ctx, p.x, p.y, styleOverride, fabricObject);
          }
        }
      });
      ctx.restore();
      return this;
    };
  };

  /**
   * Sets specific properties of fabric instance overriding its defaults
   *
   * @param {Object} _fabric fabric instance
   */
  const setFabricConfiguration = ({ _fabric }: any) => {
    _fabric.disableStyleCopyPaste = true;
  };

  useEffect(() => {
    const iconsConfig = config?.customControls?.icons;
    const deleteIcon = new Image();
    const rotateIcon = new Image();
    const scaleIcon = new Image();
    const circleIcon = new Image();
    const rectangleIcon = new Image();

    deleteIcon.src = iconsConfig?.deleteIcon ?? '';
    rotateIcon.src = iconsConfig?.rotateIcon ?? '';
    scaleIcon.src = iconsConfig?.scaleIcon ?? '';
    rectangleIcon.src = iconsConfig?.rectangleIcon ?? '';
    circleIcon.src = iconsConfig?.circleIcon ?? '';

    const fabricObj = {
      _fabric: windowFabric,
    };

    setCustomControls(fabricObj, { deleteIcon, rotateIcon, scaleIcon, rectangleIcon, circleIcon });
    setFabricConfiguration(fabricObj);
  }, [windowFabric]);

  useEffect(() => {
    if (windowFabric && initializedData?.project_type_code === CardType.SAS) {
      const fabricObj = {
        _fabric: windowFabric,
      };

      setCustomCroppingControls(fabricObj);
    }
  }, [initializedData?.project_type_code, windowFabric]);
  return <>{children}</>;
};
