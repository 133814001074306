import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import PrintOnDemand from './App';
import { RegionalCodesList } from './global-types';
import './i18n';
import './index.css';
import { getTokensFromCookies, removeTokens } from './utils';
import { isAnyOfRegions } from './utils/utility';

const locale = process.env.REACT_APP_THEME;

if (isAnyOfRegions([RegionalCodesList.ce, RegionalCodesList.uk])) {
  // Shopify stores the tokens on Cookies, but our api-middleware needs them on localStorage
  getTokensFromCookies();
}

if (locale === RegionalCodesList.us) {
  removeTokens();
}

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found');
}

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <PrintOnDemand />
  </StrictMode>,
);
