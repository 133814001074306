import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alignment } from '@hallmark/web.page-components.print-on-demand.widgets.alignment';
import { FontSize } from '@hallmark/web.page-components.print-on-demand.widgets.font-size';
import { FontStyle } from '@hallmark/web.page-components.print-on-demand.widgets.font-style';
import { useInitializationDataContext } from '../../../../context/data-context';
import { FabricTextBox } from '../../../../global-types';
import { useActiveCanvas } from '../../../../hooks';
import {
  getGroupedTextObject,
  CanvasDataTypes,
  convertPointToPixel,
  convertPixelToPoint,
  getFontName,
} from '../../../../utils';
import { Font, FontSelectedOption } from '../font-drawer-types';
import styles from '../font-drawer.module.scss';
import { ALIGNMENT_OPTIONS } from '../utils/constants';
import { findKeyByValue } from '../utils/find-key-by-value';
import { formatFontCollection } from '../utils/format-font-collection';
import { getLabelByFontId } from '../utils/get-label-by-fontid';

export const FontTab = (): React.ReactElement => {
  const canvas = useActiveCanvas();
  const textObject: fabric.Textbox = getGroupedTextObject(canvas?.current) as fabric.Textbox;
  const { t } = useTranslation();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const [fonts, setFonts] = useState<Font[]>([]);
  const originalFontSize = textObject?.fontSize ? convertPixelToPoint(textObject.fontSize) : 30;
  const originalFontFamily = textObject?.fontFamily
    ? getLabelByFontId(textObject?.fontFamily, initializedData?.font_collection.fonts)
    : 'Hey Sunshine';
  const originalAlignment = findKeyByValue(textObject?.textAlign);
  const [fontSizeValue, setFontSizeValue] = useState(originalFontSize);
  const [selectedFont, setSelectedFont] = useState(originalFontFamily);
  const activeObject = canvas?.current?.getActiveObject() as FabricTextBox;
  const shouldShowFontResizeControls =
    activeObject.CanEditFontSize || activeObject.data.type === CanvasDataTypes.UserText;

  const updateCanvasAndFireChange = () => {
    canvas?.current?.renderAll();
    textObject.fire('changed');
  };

  useEffect(() => {
    const fontCollection = formatFontCollection(initializedData?.font_collection.fonts);
    if (fontCollection) {
      setFonts(fontCollection);
    }
  }, [initializedData?.font_collection.fonts]);

  useEffect(() => {
    const scaledFontSize = convertPointToPixel(fontSizeValue);
    textObject.setOptions({ fontSize: scaledFontSize });
    updateCanvasAndFireChange();
  }, [fontSizeValue]);

  const handleFontStyleChange = (option: FontSelectedOption) => {
    if (textObject) {
      setSelectedFont(option.label);
      textObject.setOptions({ fontFamily: getFontName(option.id) });
      updateCanvasAndFireChange();
    }
  };

  const handleFontSizeChange = (action: string) => {
    if (action === 'increase') {
      setFontSizeValue(fontSizeValue + 1);
    } else {
      setFontSizeValue(fontSizeValue - 1);
    }
  };

  const handleAlignmentChange = (index: number) => {
    if (textObject) {
      const textAlignment = (ALIGNMENT_OPTIONS[index.toString()] || 'left').toLowerCase();
      textObject.setOptions({
        textAlign: textAlignment,
      });
      updateCanvasAndFireChange();
    }
  };

  return (
    <div className={styles['text-tab']} data-testid="font-tab">
      <FontStyle
        addClass={styles['font-style']}
        label={t('fontDrawer.fontStyle')}
        fonts={fonts}
        selectedFont={selectedFont}
        onChange={(option) => handleFontStyleChange(option as FontSelectedOption)}
      />
      <div className={styles['counter-alignment-container']}>
        {shouldShowFontResizeControls && (
          <FontSize
            addClass={styles['font-size']}
            label={t('fontDrawer.fontSize')}
            onClickButton={handleFontSizeChange}
            fontSizeValue={fontSizeValue}
            upper={60}
            lower={5}
            testId="font-size"
          />
        )}
        <Alignment
          label={t('fontDrawer.alignment')}
          addClass={styles.alignment}
          buttonGroupClick={handleAlignmentChange}
          initialValue={originalAlignment}
        />
      </div>
    </div>
  );
};
