import React, { useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { Carousel } from '@hallmark/web.core.surfaces.carousel';
import { useAppContext } from '../../context/app-context';
import { setCardFacesLoaded, setIsSystemErrorOpen } from '../../context/app-context/app-context-actions';
import { useCardContext } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import { CardFaceData, EditableTextSettings, FabricTextBox } from '../../global-types';
import { useLoadCardData, useFeatureFlags, useIsPodProductCode } from '../../hooks';
import { useSystemFailureDialog } from '../../hooks/useSystemFailureDialog';
import { config } from '../../regional-config';
import {
  CanvasDataTypes,
  getFontName,
  getSizeAfterScaling,
  setEditableTextHandlers,
  toggleTamWarning,
  getEditableTextButton,
} from '../../utils';
import { createEditableInput } from '../../utils/canvas-settings';
import { validateTransformedData } from '../../utils/canvas-settings/validate-transformed-data';
import { getMaxTextLines } from '../../utils/helper-settings';
import { CardFace } from '../card-face';
import { SystemFailureDialog } from '../system-failure-dialog';
import { CardEditorProps } from './card-editor-types';
import {
  getEditableTextSettings,
  getPhotoZoneSettings,
  getPhotoTextZoneSettings,
  addPhotoTextZoneButton,
  getPhotoZoneButton,
  getPhotoFrameImage,
} from './card-editor-utils';
import styles from './card-editor.module.scss';
import { addEditableAreas, addPlaceholders } from './utils';

export const CardEditor = ({ cardFaceIndex }: CardEditorProps): React.ReactElement => {
  const { cardState } = useCardContext();
  const { initializedDataState } = useInitializationDataContext();
  const {
    appState: { cardFacesLoaded },
    appDispatch,
  } = useAppContext();
  const { loadCardData } = useLoadCardData();
  const { DYNAMIC_TEXBOXES } = useFeatureFlags();
  const { isUK } = initializedDataState;
  const isPodProductCode = useIsPodProductCode();
  const { handleSystemFailureAction, handleSystemFailureCancel, handleSystemFailureClose } = useSystemFailureDialog();

  const { t } = useTranslation();

  const defaultEditableTextMessage = t('cardFace.defaultEditableMessage');

  async function addEditableTexts(face: CardFaceData) {
    const canvas = face.canvas.current;
    if (!canvas) {
      return;
    }

    const editableTexts = await Promise.all(
      face.texts.map(async (currentText, index) => {
        if (currentText.IsHybrid) {
          return;
        }

        const settings = getEditableTextSettings(currentText, cardState) as EditableTextSettings;
        const editableInput = createEditableInput(settings, face.faceId, index, DYNAMIC_TEXBOXES);
        const editIconScale = getSizeAfterScaling(40, canvas);
        const maxLines = getMaxTextLines(currentText);
        return getEditableTextButton(settings, editIconScale).then((image) => {
          setEditableTextHandlers(image, editableInput, settings.fontSize, maxLines, cardState);
          return [editableInput, image];
        });
      }),
    );

    editableTexts.forEach((text) => {
      if (!text) {
        return;
      }
      canvas.add(...text);
    });
  }

  async function addPhotoZones(cardFace: CardFaceData) {
    const canvas = cardFace.canvas.current;
    if (!canvas) {
      return;
    }
    const photoZonesButtons = await Promise.all(
      cardFace.zones
        .map((photoZone, index) => {
          const zoneSettings = getPhotoZoneSettings(photoZone, index, cardState);
          const zone = new fabric.Rect(zoneSettings);
          zone.set('fill', config.cardEditor?.photoZoneBackgroundColor);
          canvas.add(zone);
          return getPhotoZoneButton(zone, canvas);
        })
        .filter((zones) => zones !== undefined),
    );

    canvas.add(...(photoZonesButtons as fabric.Object[]));
  }

  async function addPhotoTextZones(face: CardFaceData) {
    const canvas = face.canvas.current;
    if (!canvas) {
      return;
    }
    await Promise.all(
      face.texts
        .map((zone, index) => {
          if (!zone.IsHybrid) {
            return;
          }
          let label = t('cardEditor.message');
          // Get base zone settings
          const zoneSettings = getPhotoTextZoneSettings(zone, index);
          // Get dotted photoTextZone border
          const photoTextZoneBorder = new fabric.Rect(zoneSettings);
          // Add Clip path so that text respects bounding box
          const clipPath = new fabric.Rect({
            ...zoneSettings,
            absolutePositioned: true,
          });
          // Add textbox to be populated with TAM.
          const originalText = new fabric.Textbox('', {
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: 32,
            top: Math.ceil(zoneSettings.top - zoneSettings.height / 2),
            left: Math.ceil(zoneSettings.left - zoneSettings.width / 2),
            fontFamily: getFontName(config?.screen?.defaultFontId ?? 107),
            width: zoneSettings.width,
            hasBorders: false,
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
            data: {
              linkedZoneName: zoneSettings.name,
              ID: zone.ID,
            },
            CanResizeTextArea: zoneSettings.CanResizeTextArea,
            name: `${CanvasDataTypes.PhotoZoneTextbox}-${index}`,
            clipPath,
          } as FabricTextBox);

          // Add all objects to photoTextZone group
          const photoTextZone = new fabric.Group([originalText, photoTextZoneBorder], zoneSettings);

          originalText.on('changed', function (this: fabric.Textbox) {
            toggleTamWarning(this, photoTextZone, appDispatch, t);
          });

          canvas.add(photoTextZone);
          if (index === 0 && face.texts.length > 1) {
            label = t('cardEditor.greeting');
          }
          return addPhotoTextZoneButton(photoTextZone as fabric.Group & FabricTextBox, canvas, label);
        })
        .filter(
          //clean hybrid zones
          (btn) => btn !== undefined,
        ),
    );
  }

  async function addPhotoFrame(face: CardFaceData) {
    const { photoZoneTemplate } = face;
    const canvas = face.canvas.current;
    if (!photoZoneTemplate || photoZoneTemplate === null || !canvas) {
      return;
    }
    const image = await getPhotoFrameImage(face, cardState);
    canvas.add(image);
  }

  useEffect(() => {
    loadCardData();
  }, [loadCardData]);

  const loadCardFace = useCallback(
    async (face: CardFaceData) => {
      const hasCanvas = !!face.canvas?.current;
      // If loading card with json from the edit view, we do not need to load the zones.
      if (!hasCanvas || face.originalCanvasJson) {
        return;
      }

      if (face.zones.length > 0) {
        await addPhotoZones(face);
      }
      if (face.photoZoneTemplate !== null) {
        await addPhotoFrame(face);
      }
      await addPhotoTextZones(face);
      await addEditableTexts(face);

      addEditableAreas(face, cardState);
      addPlaceholders(face, initializedDataState, cardState, isUK, isPodProductCode, defaultEditableTextMessage);
    },
    [cardState],
  );

  const loadCardFaces = useCallback(async () => {
    if (cardFacesLoaded || cardState.cardFacesList.length === 0) {
      return;
    }
    const loadedFaces = cardState.cardFacesList.map((face) => loadCardFace(face));
    await Promise.all(loadedFaces);
    setCardFacesLoaded(appDispatch, true);

    cardState.cardFacesList.forEach((face) => {
      if (!face.originalCanvasJson) {
        const templateCardFace = initializedDataState.data?.variables.template_data?.Faces.find(
          (templateCardFace) => templateCardFace.FaceId === face.faceId,
        );
        if (templateCardFace && validateTransformedData(face, templateCardFace)) {
          setIsSystemErrorOpen(appDispatch, true);
          throw new Error('The current json has less objects than the template json');
        }
      }
    });
  }, [loadCardFace, cardState.cardFacesList, cardFacesLoaded]);

  useEffect(() => {
    loadCardFaces();
  }, [loadCardFaces]);

  return (
    <div className={styles['card-editor']}>
      <ErrorBoundary
        FallbackComponent={() => (
          <SystemFailureDialog
            actionButton={handleSystemFailureAction}
            cancelButton={handleSystemFailureCancel}
            onClose={handleSystemFailureClose}
            isOpen={true}
          />
        )}
      >
        <Carousel
          disableKeyboardControls={true}
          slidesToShow={1}
          slidesToScroll={1}
          dragging={false}
          addClass={styles['carousel']}
          slideClass={styles['card-slide']}
          currentSlideIndex={cardFaceIndex}
        >
          {cardState.cardFacesList
            .filter((face) => face.editorDisplayIndicator)
            .map((face: CardFaceData, index: number) => (
              <CardFace cardIndex={index} cardFace={face} key={face.faceId} />
            ))}
        </Carousel>
      </ErrorBoundary>
    </div>
  );
};
