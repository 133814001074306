enum CEBrandColors {
  Purple = 'ce-purple',
  Ochre = 'ce-ochre',
  Pink = 'ce-pink',
  Gradient = 'ce-gradient',
  Blue = 'ce-blue',
  OffWhite = 'ce-off-white',
  Background = 'ce-background',
  Purple10 = 'ce-10-purple',
  Ochre10 = 'ce-10-ochre',
  Pink10 = 'ce-10-pink',
  LightGray = 'ce-light-gray',
  MediumGrey = 'ce-medium-gray',
  Anthracite = 'ce-anthracite',
  Positive = 'ce-positive',
  Negative = 'ce-negative',
  Ochre7 = 'ce-7-ochre',
  Ochre20 = 'ce-20-ochre',
}

enum CEDefaultColors {
  DefaultBlack = 'ce-default-black', // #000000
  DefaultBlue = 'ce-default-blue', // #1319c7
  DefaultGreen = 'ce-default-green', // #55d602
  DefaultRed = 'ce-default-red', // #cd0a1b
  DefaultMagenta = 'ce-default-magenta', // #bc1775
  DefaultPurple = 'ce-default-purple', // #ae18f2
}

enum CEShadeColors {
  // First Row
  DarkBlack = 'ce-dark-black',
  CharcoalGray = 'ce-charcoal-gray',
  MediumGray = 'ce-medium-gray',
  LightGray = 'ce-light-gray-shade',
  VeryLightGray = 'ce-very-light-gray',
  PureWhite = 'ce-pure-white',

  // Second Row
  DarkNavyBlue = 'ce-dark-navy-blue',
  DeepTeal = 'ce-deep-teal',
  BrightBlue = 'ce-bright-blue',
  SkyBlue = 'ce-sky-blue',
  SoftMint = 'ce-soft-mint',
  MutedTeal = 'ce-muted-teal',

  // Third Row
  DeepForestGreen = 'ce-deep-forest-green',
  DarkJungleGreen = 'ce-dark-jungle-green',
  BrightGreen = 'ce-bright-green',
  LightGreen = 'ce-light-green',
  MutedGreen = 'ce-muted-green',
  LightLime = 'ce-light-lime',

  // Fourth Row
  SoftGoldenrod = 'ce-soft-goldenrod',
  BrightYellow = 'ce-bright-yellow',
  LightYellow = 'ce-light-yellow',
  PaleYellow = 'ce-pale-yellow',
  LightPaleYellow = 'ce-light-pale-yellow',
  PalePink = 'ce-pale-pink',

  // Fifth Row
  WarmOrange = 'ce-warm-orange',
  WarmSunsetOrange = 'ce-warm-sunset-orange',
  SoftOrange = 'ce-soft-orange',
  Peach = 'ce-peach',
  LightPeach = 'ce-light-peach',
  WarmBlush = 'ce-warm-blush',

  // Sixth Row
  BrightRed = 'ce-bright-red',
  DarkCrimson = 'ce-dark-crimson',
  VividRed = 'ce-vivid-red',
  SoftRed = 'ce-soft-red',
  MutedRose = 'ce-muted-rose',
  SoftRose = 'ce-soft-rose',

  // Seventh Row
  DeepBurgundy = 'ce-deep-burgundy',
  DeepRose = 'ce-deep-rose',
  BrightMagenta = 'ce-bright-magenta',
  BrightPink = 'ce-bright-pink',
  LightPink = 'ce-light-pink',
  LightRose = 'ce-light-rose',

  // Eighth Row
  DarkPlum = 'ce-dark-plum',
  DarkAmethyst = 'ce-dark-amethyst',
  LightPurple = 'ce-light-purple',
  Lavender = 'ce-lavender',
  SoftLavender = 'ce-soft-lavender',
  LightLilac = 'ce-light-lilac',

  // Ninth Row
  WarmBrown = 'ce-warm-brown',
  DeepOliveBrown = 'ce-deep-olive-brown',
  DeepTan = 'ce-deep-tan',
  SoftBrown = 'ce-soft-brown',
  MutedBrown = 'ce-muted-brown',
  MutedTan = 'ce-muted-tan',
}

export { CEBrandColors, CEShadeColors, CEDefaultColors };
