import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { ButtonGroup, ButtonGroupVariant } from '@hallmark/web.core.buttons.button-group';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CEIconNames } from '@hallmark/web.styles.fonts.icomoon';
import { config } from '../../../regional-config';
import { CEShadeColors } from '../../colors/colors-types';
import { ColorPalleteDrawerProps } from './color-pallete-drawer-types';
import styles from './color-pallete-drawer.module.scss';

export const ColorPalleteDrawer = ({
  isOpen,
  onClose,
  onColorSelect,
  selectedColor,
}: ColorPalleteDrawerProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Drawer
      addClass={styles['color-palette-drawer']}
      drawerPosition={DrawerPositions.Left}
      isOpen={isOpen}
      onClose={onClose}
      closeIcon={CEIconNames.XClose}
      headerContent={
        <Typography variant={config?.colorPalleteDrawer?.titleTypographyVariant} addClass={styles.header}>
          {t('colorPaletteDrawer.addColor')}
        </Typography>
      }
    >
      <div className={styles['footer-wrapper']}>
        <ButtonGroup
          ariaLabel="Text Color"
          addClass={styles['button-group']}
          defaultClickedIndex={Object.values(CEShadeColors).indexOf(selectedColor as CEShadeColors)}
          variant={ButtonGroupVariant.Secondary}
        >
          {Object.entries(CEShadeColors).map(([name, color]) => {
            return (
              <Button
                mode={ButtonModes.Icon}
                addClass={classNames(styles['color-button'], styles[`shade-color-${color}`])}
                key={name}
                ariaLabel={name}
                click={() => onColorSelect(color)}
              >
                <Typography variant={TypographyVariants.SRT}>{name}</Typography>
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
    </Drawer>
  );
};
