import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { Button } from '@hallmark/web.core.buttons.button';
import { Drawer, DrawerLayoutVariants, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CEIconNames } from '@hallmark/web.styles.fonts.icomoon';
import {
  setIsOrderDrawerOpen,
  setIsScaleDrawerOpen,
  setIsRotationDrawerOpen,
  setIsResetConfirmationDialogOpen,
  useAppContext,
  showLoadingScreen,
  setIsToasterOpen,
  hideLoadingScreen,
  setDialogType,
} from '../../../context/app-context';
import { ImageEditDrawerModes } from '../../../context/app-context/app-context-types';
import { useCardContext } from '../../../context/card-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { CardType } from '../../../global-types/card';
import { FillPhotoZoneType } from '../../../global-types/card-face';
import { DialogType } from '../../../global-types/dialog';
import { useActiveCanvas, useFeatureFlags, useIsPodProductCode } from '../../../hooks';
import { config } from '../../../regional-config';
import {
  getObjectByName,
  hideMiddleControls,
  fillPhotoZone,
  getCardFaceClipPath,
  CanvasDataTypes,
} from '../../../utils';
import uploadImage from '../../../utils/utility/image-upload';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog';
import { useImageUploadHandlers } from '../drawer-container/hooks/useImageUploadHandlers';
import { CEOptionsContainer } from './fragments/CEOptionsContainer';
import { USUKOptionsContainer } from './fragments/USUKOptionsContainer';
import { ImageEditDrawerProps } from './image-edit-drawer-types';
import styles from './image-edit-drawer.module.scss';

export const ImageEditDrawer = ({
  isOpen,
  mode,
  onClose,
  onDelete,
  onCrop,
  isCropping,
  onImageSelect,
  onFinishCropping,
  onCancelCropping,
  isHandwriting,
}: ImageEditDrawerProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isReplaceConfirmationOpen, setIsReplaceConfirmationOpen] = useState<boolean>(false);
  const {
    appDispatch,
    appState: { dialogType },
  } = useAppContext();
  const {
    cardState: { cardFacesList, activeCardIndex },
  } = useCardContext();
  const canvas = useActiveCanvas();
  const {
    initializedDataState: { isUS, data: initializedData },
  } = useInitializationDataContext();
  const { S3_PHOTO_UPLOAD } = useFeatureFlags();
  const isPodProductCode = useIsPodProductCode();
  const isStickerEditMode = mode === ImageEditDrawerModes.StickerImage;
  const { openImageEditDrawer } = useImageUploadHandlers(
    isStickerEditMode ? ImageEditDrawerModes.StickerImage : ImageEditDrawerModes.UserImage,
  );
  const isSignAndSend = initializedData?.project_type_code === CardType.SAS;
  const isPhotoZoneImage = canvas?.current?.getActiveObject()?.data?.type === CanvasDataTypes.PhotoZoneImage;
  const currentCardFace = cardFacesList[`${activeCardIndex}`];

  const { t } = useTranslation();

  const setError = (title: string, error: string) => {
    setIsToasterOpen(appDispatch, {
      variant: ToastVariants.Error,
      title,
      children: error,
    });
  };

  const setLoadingScreen = (message: string) => {
    showLoadingScreen(appDispatch, t(message));
  };

  const handleImageUpload = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
    const uploadedFiles = (event.target as HTMLInputElement).files;

    if (uploadedFiles) {
      const isInvalidSize = uploadedFiles[0].size >= config.imagesUpload.maxFileSize;
      if (isInvalidSize) {
        onClose();
        return setError(`${t('imageEditDrawer.setErrorExceeded')}`, `${t('imageEditDrawer.sizeError')}`);
      }

      const { imageUrl, imageId, cleanUpVersionId, errorMessage } = await uploadImage(
        uploadedFiles[0],
        setError,
        setLoadingScreen,
        initializedData,
        appDispatch,
        S3_PHOTO_UPLOAD,
        t,
      );
      if (!imageUrl && errorMessage) {
        hideLoadingScreen(appDispatch);
        return;
      }
      fabric.Image.fromURL(
        imageUrl as string,
        (img) => {
          hideMiddleControls(img);
          const cardFaceClipPath = getCardFaceClipPath(currentCardFace, 0);
          if (cardFaceClipPath) {
            // Ensure image respects copyrighted zone boundaries
            img.clipPath = cardFaceClipPath;
          }
          const activeImage = canvas?.current?.getActiveObject();
          if (!activeImage || activeImage.type !== 'image') {
            return;
          }
          if (isPodProductCode) {
            // photo zone image
            const photoZoneId = activeImage.data?.photoZoneId;
            if (photoZoneId) {
              img.set({
                data: {
                  ...activeImage.data,
                  version_id: cleanUpVersionId,
                },
                name: imageId,
              });
              canvas?.current?.remove(activeImage);
              fillPhotoZone(photoZoneId, img, cardFacesList, FillPhotoZoneType.UPLOADING);
              hideLoadingScreen(appDispatch);
              openImageEditDrawer();
              onImageSelect();
              return;
            }
            img.scaleToWidth(200);
            img.scaleToHeight(200);
            img.set({
              left: activeImage.left,
              top: activeImage.top,
              opacity: 1,
              name: imageId,
              data: {
                ...activeImage.data,
                version_id: cleanUpVersionId,
              },
            });
            img.onSelect = activeImage.onSelect;
            canvas?.current?.remove(activeImage);
            canvas?.current?.add(img);
            canvas?.current?.setActiveObject(img);
            onClose();
            openImageEditDrawer();
            onImageSelect();
            hideLoadingScreen(appDispatch);
            return;
          }

          const activeZone = getObjectByName(activeImage.data?.zoneName, canvas?.current as fabric.Canvas);
          if (!activeZone) {
            return;
          }
          const { left, top, width, height } = activeZone;

          img.scaleToWidth(width as number);
          if (img.getScaledHeight() > (height as number)) {
            img.scaleToHeight(height as number);
          }
          const oImg = img.set({
            left: (left as number) - img.getScaledWidth() / 2,
            top: (top as number) - (height as number) / 2,
            originX: 'left',
            originY: 'top',
            name: imageId,
            data: {
              ...activeImage.data,
              version_id: cleanUpVersionId,
            },
          });
          oImg.onSelect = activeImage.onSelect;
          canvas?.current?.remove(activeImage);
          canvas?.current?.add(oImg);
          canvas?.current?.setActiveObject(oImg);
          openImageEditDrawer();
          onImageSelect();
          onCrop();
          hideLoadingScreen(appDispatch);
        },
        { crossOrigin: 'anonymous' },
      );
    }
  };

  const handleImageReplace = () => {
    inputRef.current?.click();
    setIsReplaceConfirmationOpen(false);
  };

  const onSubmit = () => {
    if (isCropping && onFinishCropping) {
      onFinishCropping();
    }
    onClose();
  };

  const onSizeChange = () => {
    setIsScaleDrawerOpen(appDispatch);
  };
  const onRotateChange = () => {
    setIsRotationDrawerOpen(appDispatch);
  };
  const onOrderChange = () => {
    setIsOrderDrawerOpen(appDispatch);
  };
  const onReplace = () => {
    setIsReplaceConfirmationOpen(true);
  };
  const onReset = () => {
    setIsResetConfirmationDialogOpen(appDispatch);
  };

  useEffect(() => {
    setDialogType(appDispatch, isStickerEditMode ? DialogType.Sticker : DialogType.Image);
  }, [dialogType]);

  const getOptionsContainer = () => {
    switch (config?.imageEditDrawer?.headerContentType) {
      case 'Variant1':
        return (
          <USUKOptionsContainer
            onCrop={onCrop}
            onSizeChange={onSizeChange}
            onRotateChange={onRotateChange}
            onDelete={onDelete}
            onOrderChange={onOrderChange}
            onReplace={onReplace}
            onReset={onReset}
            isPodProductCode={isPodProductCode}
            isPhotoZoneImage={isPhotoZoneImage}
            isHandwriting={isHandwriting}
            isUS={isUS}
            isSignAndSend={isSignAndSend}
          />
        );
      case 'Variant2':
        return (
          <CEOptionsContainer
            onCrop={onCrop}
            onSizeChange={onSizeChange}
            onRotateChange={onRotateChange}
            onDelete={onDelete}
            onOrderChange={onOrderChange}
            onReplace={onReplace}
            onReset={onReset}
            isPodProductCode={isPodProductCode}
            isPhotoZoneImage={isPhotoZoneImage}
            isSignAndSend={isSignAndSend}
            isStickerEditMode={isStickerEditMode}
          />
        );
      default:
        return null;
    }
  };

  const ImageEditFooterContent = (): ReactElement | null => {
    // Render based on the new condition combined with the existing logic
    return config?.imageEditDrawer?.hasFooterContent || isCropping ? (
      <div className={styles['submit-container']}>
        {isCropping && (
          <Button
            mode={config?.imageEditDrawer?.cancelButtonMode}
            click={onCancelCropping}
            addClass={styles['secondary-button']}
          >
            {t('imageEditDrawer.cancel')}
          </Button>
        )}
        <Button mode={config?.imageEditDrawer?.applyButtonMode} click={onSubmit} addClass={styles['primary-button']}>
          {isCropping ? `${t('imageEditDrawer.apply')}` : `${t('imageEditDrawer.ok')}`}
        </Button>
      </div>
    ) : null;
  };

  return (
    <>
      <ConfirmationDialog
        domId="replace-confirmation"
        title={`${t('imageEditDrawer.replaceConfirmationTitle')}`}
        cancelButtonText={`${t('imageEditDrawer.replaceConfirmationCancelText')}`}
        actionButtonText={`${t('imageEditDrawer.replaceConfirmationConfirmText')}`}
        isOpen={isReplaceConfirmationOpen}
        onClose={() => setIsReplaceConfirmationOpen(false)}
        onConfirm={handleImageReplace}
      />
      <Drawer
        drawerPosition={DrawerPositions.Bottom}
        closeButtonText={`${t('imageEditDrawer.closeDrawer')}`}
        isOpen={isOpen}
        closeIcon={CEIconNames.XClose}
        hideCloseButton={isCropping}
        showVeil={false}
        onClose={onClose}
        role="toolbar"
        layoutVariant={
          config?.imageEditDrawer?.hasLayoutVariant
            ? !isCropping
              ? DrawerLayoutVariants.Limited
              : DrawerLayoutVariants.Absolute
            : undefined
        }
        headerContent={
          config?.imageEditDrawer?.hasHeaderContent &&
          !isCropping && (
            <Typography variant={TypographyVariants.BodyBold}>
              {t(isStickerEditMode ? 'imageEditDrawer.stickerEditorDrawer' : 'imageEditDrawer.photoEditorDrawer')}
            </Typography>
          )
        }
      >
        {<div className={styles['options-container']}>{!isCropping && getOptionsContainer()}</div>}
        <ImageEditFooterContent />
        <input
          type="file"
          aria-labelledby="wam-replace-button"
          ref={inputRef}
          onChange={handleImageUpload}
          style={{ display: 'none' }}
          accept="image/jpeg,image/jpg,image/gif,image/png,image/heic,image/heif,image/webp "
        />
      </Drawer>
    </>
  );
};
