import React, { useRef, useState, useEffect } from 'react';
import { ButtonGroup } from '@hallmark/web.core.buttons.button-group';
import { CarouselImageDotButton } from '@hallmark/web.core.surfaces.carousel';
import { setActiveCardIndex, useCardContext } from '../../context/card-context';
import { CardFaceData, CardFacePreviewImage } from '../../global-types';
import { config } from '../../regional-config';
import { CardSelectBarProps } from './card-select-bar-types';
import styles from './card-select-bar.module.scss';
import { getThumbnailImage } from './utils';

export const CardSelectBar = ({ faces, slideIndex, handleIndexChange, mode }: CardSelectBarProps) => {
  const { cardDispatch } = useCardContext();
  const [focusedTabIndex, setFocusedTabIndex] = useState<number>(slideIndex);
  const tabsRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const filteredFaces: (CardFaceData | CardFacePreviewImage)[] =
    mode === 'edit'
      ? (faces as CardFaceData[]).filter((face) => face.editorDisplayIndicator)
      : (faces as CardFacePreviewImage[]);

  useEffect(() => {
    tabsRefs.current = Array(filteredFaces.length).fill(null);
    return () => {
      tabsRefs.current = [];
    };
  }, [filteredFaces]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    const lastTab = tabsRefs.current.length - 1;
    let currentFocusedTabIndex = focusedTabIndex;

    if (e.key === 'ArrowLeft') {
      currentFocusedTabIndex = currentFocusedTabIndex === 0 ? lastTab : currentFocusedTabIndex - 1;
    } else if (e.key === 'ArrowRight') {
      currentFocusedTabIndex = currentFocusedTabIndex === lastTab ? 0 : currentFocusedTabIndex + 1;
    }

    const currentTab = tabsRefs.current[`${currentFocusedTabIndex}`] || null;

    if (currentTab) {
      currentTab.focus();
    }

    setFocusedTabIndex(currentFocusedTabIndex);
  };

  return (
    <ButtonGroup
      defaultClickedIndex={slideIndex}
      activeClassOverride={styles['active-image-dot-button']}
      addClass={styles['carousel-image-dot-container']}
      onIndexChange={handleIndexChange}
      groupRole="tablist"
      ariaLabel="Carousel Slides"
    >
      {filteredFaces.map((face, index) => (
        <CarouselImageDotButton
          key={`face-${index}-button`}
          click={() => face.faceNumber !== undefined && setActiveCardIndex(cardDispatch, face.faceNumber - 1)}
          image={getThumbnailImage(face) || ''}
          altText={face?.type?.replace('-', ' ')}
          addClass={styles['image-dot-button']}
          ariaLabel={face.type}
          role="tab"
          ariaSelected={focusedTabIndex === index}
          ariaControls={`${index + 1}_TABPANEL`}
          id={`${index + 1}_TAB`}
          ref={(ref) => {
            if (ref instanceof HTMLButtonElement) {
              tabsRefs.current[`${index}`] = ref;
            }
          }}
          tabIndex={focusedTabIndex === index ? 0 : -1}
          onKeyDown={onKeyDown}
          testId="carousel-dot-button"
        >
          {config.cardEditor?.faceTypeText ? face?.type?.replace('-', ' ') : null}
        </CarouselImageDotButton>
      ))}
    </ButtonGroup>
  );
};
