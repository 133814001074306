import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontDrawer as FontDrawerComponent } from '@hallmark/web.page-components.print-on-demand.text-editor.font-drawer';
import { Font } from '@hallmark/web.page-components.print-on-demand.text-editor.font-drawer/dist/font-drawer-types';
import { useAppContext } from '../../../../context/app-context';
import { setIsTextDrawerOpen } from '../../../../context/app-context/app-context-actions';
import { useInitializationDataContext } from '../../../../context/data-context';
import { useActiveCanvas } from '../../../../hooks';
import { config } from '../../../../regional-config';
import { getFontId, getGroupedTextObject, getFontName } from '../../../../utils';
import styles from '../drawer-container.module.scss';

export const FontDrawer = (): React.ReactElement => {
  const { t } = useTranslation();
  const startingFontId = useRef<number>(config?.screen?.defaultFontId ?? 115);
  const [selectedId, setSelectedId] = useState<number>(config?.screen?.defaultFontId ?? 115);
  const [fonts, setFonts] = useState<Font[]>([]);
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { appState, appDispatch } = useAppContext();
  const canvas = useActiveCanvas();
  const activeTextBox = getGroupedTextObject(canvas?.current) as undefined | fabric.Textbox;

  const onClose = () => {
    setIsTextDrawerOpen(appDispatch);
    handleFontChange(startingFontId.current);
  };

  const onSubmit = () => {
    setIsTextDrawerOpen(appDispatch);
  };

  const handleFontChange = (id: number) => {
    if (activeTextBox) {
      activeTextBox.setOptions({ fontFamily: getFontName(id) });
      canvas?.current?.renderAll();
      setSelectedId(id);
      activeTextBox.fire('changed');
      activeTextBox?.fire('custom:updateBorders');
    }
  };

  useEffect(() => {
    const fontCollection = initializedData?.font_collection.fonts;
    if (fontCollection) {
      setFonts(
        fontCollection.map(({ name, id }) => ({
          id,
          family: getFontName(id),
          text: name,
        })),
      );
    }
  }, [initializedData?.font_collection.fonts]);

  useEffect(() => {
    if (activeTextBox && appState.isFontDrawerOpen) {
      startingFontId.current = getFontId(activeTextBox.fontFamily as string);
      const startingId: number = startingFontId.current;
      setSelectedId(startingId);
    }
  }, [appState.isFontDrawerOpen]);

  return (
    <FontDrawerComponent
      handleSubmit={onSubmit}
      onClose={onClose}
      handleFontChange={handleFontChange}
      fonts={fonts}
      selectedFontId={selectedId}
      isOpen={appState.isFontDrawerOpen}
      selectHoverClass={styles['select-hover-class']}
      hasActionButton={config?.fontDrawer?.hasActionButton}
      hasCancelButton={config?.fontDrawer?.hasCancelButton}
      actionButtonMode={config?.fontDrawer?.actionButtonMode}
      cancelButtonMode={config?.fontDrawer?.cancelButtonMode}
      layoutVariant={config?.fontDrawer?.layoutVariant}
      actionButtonLabel={t('drawerContainer.actionButtonLabel') ?? ''}
      cancelButtonLabel={t('drawerContainer.cancelButtonLabel') ?? ''}
    />
  );
};
