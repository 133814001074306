import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Theme } from '@hallmark/web.themes.theme-provider';
import styles from './App.module.scss';
import { SFCCRedirect } from './components/sfcc-redirect';
import { ContextProvider } from './context';
import { InitializationDataContext } from './context/data-context';
import { PODRoutes } from './routes';
import { preventUnload } from './utils';
import { getThemeForCurrentRegion } from './utils/get-theme-for-current-region';
import { initializeGtmAnalytics } from './utils/initialize-gtm';

const router = createBrowserRouter(
  [
    {
      path: '/card/customization',
      element: <SFCCRedirect />,
    },
    ...PODRoutes.map(({ route, view: Element }) => ({
      path: `/card/customization${route}`,
      element: <Element />,
    })),
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

const PrintOnDemand = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload);
  }, []);

  useEffect(() => {
    const gtmID = process.env.REACT_APP_GTM_ID;
    const gtmAuth = process.env.REACT_APP_GTM_AUTH;
    const gtmPreview = process.env.REACT_APP_GTM_PREVIEW;

    if (gtmID && gtmAuth && gtmPreview) {
      initializeGtmAnalytics(window, document, 'script', 'dataLayer', gtmID, gtmAuth, gtmPreview);
    }
  }, []);

  const themeOverrides = getThemeForCurrentRegion();

  return (
    <ContextProvider>
      <InitializationDataContext.Consumer>
        {() => (
          <Theme.ThemeProvider overrides={themeOverrides}>
            <div className={styles.app}>
              <RouterProvider router={router} />
            </div>
          </Theme.ThemeProvider>
        )}
      </InitializationDataContext.Consumer>
    </ContextProvider>
  );
};

export default PrintOnDemand;
