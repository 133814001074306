import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CEBrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { setIsToasterOpen, useAppContext } from '../../../context/app-context';
import { setActiveCardIndex, useCardContext } from '../../../context/card-context';
import { setIsPreviewDialogOpen, setPreviewSlideIndex } from '../../../context/card-context/card-context-actions';
import { useInitializationDataContext } from '../../../context/data-context';
import { CardType } from '../../../global-types';
import { useBasket, useFeatureFlags, useIsOneToMany, useSubmitOrder } from '../../../hooks';
import { useEmailOrderProcess } from '../../../hooks/useEmailOrderProcess';
import { useHandleEditingDone } from '../../../hooks/useHandleEditingDone';
import { useHandleIndexChange } from '../../../hooks/useHandleIndexChange';
import { config } from '../../../regional-config';
import { isLoggedIn } from '../../../utils';
import { PodMainPageLoaded } from '../../../utils/analytics/analytics-types';
import { pushPodMainPageLoaded } from '../../../utils/analytics/analytics-utils';
import { useUserZonesValidation } from '../../../views/editor/hooks';
import useCardZoneValidation from '../../../views/editor/hooks/useCardZoneValidation';
import { useEditorNavigation } from '../../../views/editor/hooks/useEditorNavigation';
import { useOrchestrateActions } from '../../../views/editor/hooks/useOrchestrateActions';
import { PreviewDialog } from '../../preview-dialog/preview-dialog';
import { QuantityLink } from '../../quantity-link';
import { SaveProjectLink } from '../../save-project-link';
import { ScreenLoader } from '../../screen-loader';
import { CEHeaderLayoutProps } from '../print-on-demand-header-types';
import styles from '../print-on-demand-header.module.scss';

const CEHeaderLayout = ({ closeButtonRef, onCloseButtonClick, onLogoIconClick, classes }: CEHeaderLayoutProps) => {
  const { t } = useTranslation();
  const eventsTriggered = useRef(false);
  const [userAttemptedProceed, setUserAttemptedProceed] = useState(false);
  const [previewDialogData, setPreviewDialogData] = useState({ title: '', description: '' });
  const { pathname } = useLocation();
  const { SKIP_PREVIEW_STEP } = useFeatureFlags();
  const {
    appState: { isSystemErrorOpen, isSaving },
    appDispatch,
  } = useAppContext();
  const { cardState, cardDispatch } = useCardContext();
  const { images, btlyUrl, previewSlideIndex, isPreviewDialogOpen } = cardState;
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const { addToCart } = useBasket();
  const showProjectSummary = useIsOneToMany();
  const { buttonProps, getNextButtonText } = useEditorNavigation();
  const handleEditingDone = useHandleEditingDone();

  const { validateUserZones } = useUserZonesValidation();
  const { sendEmailNow, isSendingEmail } = useEmailOrderProcess();
  const { isOrchestrating } = useOrchestrateActions();
  const handleIndexChange = useHandleIndexChange({ savingCard: true });

  const projectTypeCode = sessionStorage.getItem('lastProjectTypeCode');
  const isDigitalGreetings = projectTypeCode === CardType.DG;
  const isPreviewPage = pathname.includes('/preview');

  const updatePreviewSlideIndex = (index: number) => setPreviewSlideIndex(cardDispatch, index);
  const updateIsPreviewDialogOpen = (isOpen: boolean) => setIsPreviewDialogOpen(cardDispatch, isOpen);

  const { handleSubmit } = useSubmitOrder(updateIsPreviewDialogOpen, addToCart);

  const editableFaces = useMemo(
    () => cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
    [cardState.cardFacesList],
  );

  const hasSavedProject = localStorage.getItem('hasSavedProject') !== 'true';

  const isUserLoggedIn = isLoggedIn();
  const slideIndex = cardState.slideCardIndex;
  const isOnLastFace = slideIndex === editableFaces.length - 1;

  const showErrorToast = (titleKey: string | undefined, descriptionKey: string | undefined) => {
    handleIndexChange(0, 0);
    setIsToasterOpen(appDispatch, {
      variant: ToastVariants.Error,
      title: t(titleKey || ''),
      children: t(descriptionKey || ''),
    });
  };

  const handleValidationAndProceed = () => {
    const validation = validateZones(userAttemptedProceed);
    setPreviewDialogData({
      title: validation.popupMessage?.title ?? '',
      description: validation.popupMessage?.description ?? '',
    });

    if (!validation.canProceed) {
      if (validation.showPopup) {
        updateIsPreviewDialogOpen(true);
      } else {
        showErrorToast(validation.toastMessage?.title, validation.toastMessage?.description);
        setUserAttemptedProceed(true);
      }
    } else {
      updateIsPreviewDialogOpen(true);
    }
  };

  const handleIncrementSlideIndex = () => {
    const newActiveCardIndex = editableFaces[slideIndex + 1].faceNumber - 1;
    handleIndexChange(slideIndex + 1, newActiveCardIndex);
  };

  const handleOrder = () => {
    if (previewSlideIndex < images.length - 1) {
      setAnalyticsIndex(previewSlideIndex + 1);
    } else {
      isDigitalGreetings ? sendEmailNow(btlyUrl) : handleSubmit(images);
    }
  };

  const previewButtonProps = {
    click: handleOrder,
    children: t('editorView.lastFace'),
    disabled: isSendingEmail,
  };

  const determineButtonProps = () => {
    if (SKIP_PREVIEW_STEP) {
      return {
        click: isOnLastFace ? handleValidationAndProceed : handleIncrementSlideIndex,
        children: getNextButtonText(),
        disabled:
          isOrchestrating ||
          (isOnLastFace && initializedData?.project_type_code === 'S' ? !validateUserZones() : false),
      };
    } else {
      return isPreviewPage ? { ...previewButtonProps } : buttonProps;
    }
  };

  const currentButtonProps = determineButtonProps();

  const { validateZones } = useCardZoneValidation();

  const setAnalyticsIndex = (index: number) => {
    updatePreviewSlideIndex(index);
    setActiveCardIndex(cardDispatch, index);
  };

  useEffect(() => {
    if (!eventsTriggered.current) {
      const mainPageEventData: Omit<PodMainPageLoaded, 'event_id'> = {
        event: 'pod_main_page_loaded',
        front_editable: cardState?.cardFacesList[0]?.isEditable || false,
        inside_editable: cardState?.cardFacesList[1]?.isEditable || false,
        item_id: cardState?.projectId,
        item_name: cardState?.cardTemplateName,
        card_template_id: cardState?.cardTemplateId,
      };
      pushPodMainPageLoaded(mainPageEventData);

      eventsTriggered.current = true;
    }
  }, []);

  return (
    <div className={classes}>
      <Button
        ref={closeButtonRef}
        mode={ButtonModes.TextLink}
        click={onCloseButtonClick}
        addClass={styles['back-button']}
      >
        {t('header.back')}
      </Button>

      {showProjectSummary ? <QuantityLink /> : <div className={styles['quantity-link-placeholder']} />}

      <Button click={onLogoIconClick} addClass={styles.logo} testId="crown-icon-button" tabIndex={0}>
        <img src={config?.header?.logo} alt="Hallmark logo" />
      </Button>

      <div className={styles['save-project-modern']}>
        {isUserLoggedIn && <SaveProjectLink />}

        {!isSystemErrorOpen && (
          <Button
            {...currentButtonProps}
            endIcon={{ name: IconNames.ArrowsTailRightBold, color: 'white' }}
            mode={ButtonModes.Primary}
            testId="address-button"
            addClass={styles['address-button']}
          />
        )}
      </div>

      {hasSavedProject && (
        <ScreenLoader isVisible={isSaving}>
          <Typography variant={TypographyVariants.Headline3}>{t('header.screenLoaderMessagePart1')}</Typography>
          <Typography variant={TypographyVariants.Headline3} color={CEBrandColors.Purple}>
            {t('header.screenLoaderHighlight')}
          </Typography>
          <Typography variant={TypographyVariants.Headline3}>{t('header.screenLoaderMessagePart2')}</Typography>
        </ScreenLoader>
      )}

      {isPreviewDialogOpen && (
        <PreviewDialog
          isOpen={isPreviewDialogOpen}
          onClose={() => updateIsPreviewDialogOpen(false)}
          handleSubmit={handleEditingDone}
          data={previewDialogData}
        />
      )}
    </div>
  );
};

export default CEHeaderLayout;
