import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@hallmark/web.core.buttons.loading-button';
import { SaveButton } from '@hallmark/web.core.buttons.save-button';
import { BrandColors } from '@hallmark/web.styles.colors';
import { CEIconNames, IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAppContext } from '../../context/app-context';
import { useCardContext } from '../../context/card-context';
import { useCropContext } from '../../context/crop-context';
import { useInitializationDataContext } from '../../context/data-context';
import { useFeatureFlags, useSaveProject } from '../../hooks';
import { config } from '../../regional-config';
import { PodSaveCard } from '../../utils/analytics/analytics-types';
import { pushPodSaveCard } from '../../utils/analytics/analytics-utils';
import styles from './save-project-link.module.scss';

export const SaveProjectLink = () => {
  const { appState } = useAppContext();
  const { cardState } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { isSavingSaveButton } = appState;
  const { t } = useTranslation();
  const { saveProject } = useSaveProject();
  const { isSavingCroppedImage } = useCropContext();
  const { SAVED_PROJECTS } = useFeatureFlags();
  const handleClick = async () => {
    const saveCardEventData: Omit<PodSaveCard, 'event_id'> = {
      event: 'pod_save_card',
      item_id: cardState?.projectId,
      item_name: cardState?.cardTemplateName,
      card_template_id: cardState?.cardTemplateId,
    };
    pushPodSaveCard(saveCardEventData);

    if (SAVED_PROJECTS) {
      // Generate previews if we don't have any
      if (initializedData?.project_type_code !== 'S') {
        await saveProject({
          shouldRestoreCanvas: true,
          isSaveButtonClicked: true,
          generatePreviews: true,
        });
      }
    }
  };

  const renderSaveButton = () => {
    const buttonConfig = config.saveProjects.saveButton;

    if (buttonConfig.type === 'SaveButton') {
      return (
        <SaveButton
          isSaving={isSavingSaveButton || isSavingCroppedImage}
          onClick={handleClick}
          iconName={CEIconNames.Save}
          savingText={t('header.saving') ?? ''}
        />
      );
    } else {
      return (
        <LoadingButton
          testId="save-project-loading-button"
          addClass={styles['save-project-link']}
          click={handleClick}
          isLoading={isSavingSaveButton || isSavingCroppedImage}
          loadingColor={BrandColors.Disabled}
          defaultColor={BrandColors.Blue}
          loadingIconName={IconNames.SyncSyncInactive}
          isIconSpinning={true}
          loadingText={t('header.saving')}
          defaultText={t('header.save')}
        />
      );
    }
  };

  return renderSaveButton();
};
