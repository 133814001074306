import {
  PodMainPageLoaded,
  PodTextAdded,
  PodPhotoAdded,
  PodStickerAdded,
  PodHandwritingAdded,
  PodTextInspirationAdded,
  PodInsideCardLoaded,
  PodCardPreview,
  PodSaveCard,
  AddToCart,
} from './analytics-types';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 * Utility function to push events to the dataLayer.
 * Includes optional debug logging.
 * @param eventData - The event data to push.
 * @param debug - Whether to log the event data (default: false).
 */
export const pushToDataLayer = (eventData: Record<string, unknown>): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventData);
};

/**
 * Generates a unique event ID by combining a timestamp and a 10-digit random number.
 * @returns {string} The generated event ID.
 */
export const generateEventId = (): string => {
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 1e10);
  return `${timestamp}.${randomNum}`;
};

/**
 * Specific event pushers using the utility function.
 */
export const pushPodMainPageLoaded = (eventData: Omit<PodMainPageLoaded, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushPodTextAdded = (eventData: Omit<PodTextAdded, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushPodPhotoAdded = (eventData: Omit<PodPhotoAdded, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushPodStickerAdded = (eventData: Omit<PodStickerAdded, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushPodHandwritingAdded = (eventData: Omit<PodHandwritingAdded, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushPodTextInspirationAdded = (eventData: Omit<PodTextInspirationAdded, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushPodInsideCardLoaded = (eventData: Omit<PodInsideCardLoaded, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushPodCardPreview = (eventData: Omit<PodCardPreview, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushPodSaveCard = (eventData: Omit<PodSaveCard, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });

export const pushAddToCart = (eventData: Omit<AddToCart, 'event_id'>): void =>
  pushToDataLayer({ ...eventData, event_id: generateEventId() });
