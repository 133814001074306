import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { hideLoadingScreen, showLoadingScreen, useAppContext } from '../../../context/app-context';
import { setInitializationData, useInitializationDataContext } from '../../../context/data-context';
import { InitializationData } from '../../../global-types';
import { ErrorResponse } from '../../../global-types/services';
import { useQueryParams, useSanitizeSearchUrl, useSaveProject, useSystemErrorHandling } from '../../../hooks';
import { loadProject, migrateProjects } from '../../../services';
import { addMultipleFontFaces } from '../../../utils';

export const useMigrateAndLoadProject = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { initializationDataDispatch } = useInitializationDataContext();
  const { appDispatch } = useAppContext();
  const [onSystemError] = useSystemErrorHandling();
  const queryParams = useQueryParams();
  const { saveDraftName } = useSaveProject();
  useSanitizeSearchUrl();
  const migrateAndLoad = useCallback(
    async (migrateFromId: string, projectId: string) => {
      try {
        showLoadingScreen(appDispatch, '');
        await migrateProjects(migrateFromId, projectId);
        const loadResponse = await loadProject(projectId);
        const loadedData = loadResponse.data as InitializationData;
        const { fonts } = loadedData.font_collection;
        await addMultipleFontFaces(fonts);
        await saveDraftName(loadedData.name, true), (loadedData.is_draft = true);
        setInitializationData(initializationDataDispatch, loadedData);
      } catch (error) {
        onSystemError(error as ErrorResponse);
      } finally {
        hideLoadingScreen(appDispatch);
      }
    },
    [initializationDataDispatch, appDispatch],
  );

  useEffect(() => {
    const migrateFromId = queryParams.get('migrateFrom');
    const projectId = queryParams.get('projectId');
    if (!migrateFromId || !projectId) {
      return;
    }
    migrateAndLoad(migrateFromId, projectId);
    queryParams.delete('migrateFrom');
    navigate(`${pathname}?${queryParams.toString()}`, { replace: true });
  }, [search]);
};
