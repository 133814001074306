import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { hideLoadingScreen, setIsToasterOpen, showLoadingScreen, useAppContext } from '../context/app-context';
import { useCardContext } from '../context/card-context';
import { setShowEmailConfirmation } from '../context/card-context/card-context-actions';
import { useInitializationDataContext } from '../context/data-context';
import { ConfirmProjectDetailsRequestBody, DigitalAddress, DigitalOrderRequestBody } from '../global-types';
import { finalizeDigitalOrder } from '../services';
import { getCookie } from '../utils';

export const useEmailOrderProcess = () => {
  const { appDispatch } = useAppContext();
  const { t } = useTranslation();
  const { initializedDataState } = useInitializationDataContext();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const { cardState, cardDispatch } = useCardContext();
  const { images, showEmailConfirmation } = cardState;

  const updateShowEmailConfirmation = (show: boolean) => setShowEmailConfirmation(cardDispatch, show);

  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const projectId = initializedData?.project_id || '';
  const productId = initializedData?.product_id || '';

  const finalizeOrder = async (btlyUrl: string): Promise<void> => {
    return new Promise((resolve) => {
      if (images.length === 0) {
        resolve();
        return;
      }
      const addressData: DigitalAddress = initializedDataState.addressData.digital as DigitalAddress;
      const productData = initializedDataState.data?.product;
      const previewImage = images[0].url || '';
      const customer_number = getCookie('sfcno');
      const purchaseDate = new Date().toISOString();
      const orderRequestBody: DigitalOrderRequestBody = {
        customer_no: customer_number,
        customer_crown_card_number: '', // Keep blank for now
        enterprise_consumer_id: '', // Keep blank for now
        project_id: projectId,
        project_source_code: 'W',
        purchase_date: purchaseDate,
        sender_first_name: addressData.sender_first_name,
        sender_last_name: addressData.sender_last_name,
        product_name: productData?.product_name || '',
        product_id: productId,
        preview_image_url: previewImage,
        contact_info: addressData.contact_info, // Recipient email address
        recipient_type_code: 'E',
        tiny_url: btlyUrl,
      };

      const confirmRequestBody: ConfirmProjectDetailsRequestBody = {
        purchase_date: purchaseDate,
        order_number: '', // Get from digital-orders response
        project_ids: [projectId],
      };

      finalizeDigitalOrder(orderRequestBody, confirmRequestBody).then(() => {
        resolve();
      });
    });
  };

  const sendEmailNow = async (btlyUrl: string) => {
    setIsSendingEmail(true);
    showLoadingScreen(appDispatch, t('previewView.sendingEmailLoadingScreenTitle'));
    try {
      // TODO: add call to send email here and get success response
      finalizeOrder(btlyUrl).then(() => {
        updateShowEmailConfirmation(true);
        setIsSendingEmail(false);
      });
    } catch (errors) {
      setIsToasterOpen(appDispatch, {
        title: t('systemSendEmailNowError.title'),
        children: t('systemSendEmailNowError.description'),
        variant: ToastVariants.Error,
      });
      hideLoadingScreen(appDispatch);
      setIsSendingEmail(false);
    }
  };

  return {
    isSendingEmail,
    showEmailConfirmation,
    sendEmailNow,
  };
};
