import { GetCropImagePositionProps } from '../crop-context-types';

export const getCropImagePosition = ({ croppingArea, currentImage }: GetCropImagePositionProps) => {
  if (!croppingArea?.current || !currentImage?.aCoords) {
    throw Error('Unable to crop image: Unable to get cropped image position');
  }

  const imageCoords = Object.values(currentImage.aCoords ?? {});
  const minTopCoord = Math.min(...imageCoords.map((x) => x.y));
  const minLeftCoord = Math.min(...imageCoords.map((x) => x.x));

  const xPos = (croppingArea.current.left as number) - minLeftCoord;
  const yPos = (croppingArea.current.top as number) - minTopCoord;

  return { xPos, yPos };
};
