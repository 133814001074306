import React, { ReactNode } from 'react';
import * as Provider from '@hallmark/web.core.display.fabric-canvas';
import { Theme, ThemeSchema } from '@hallmark/web.themes.theme-provider';
import { pxToRem } from '@hallmark/web.util.px-to-rem';
import { CanvasCustomConfig } from '../components/canvas-custom-config/canvas-custom-config';
import colors from '../styles/util.scss';
import { AppContextProvider } from './app-context';
import { CardContextProvider } from './card-context';
import { CropContextProvider } from './crop-context';
import { InitializationDataContextProvider } from './data-context/data-context';

type ContextProviderProps = {
  children: ReactNode;
  shared?: unknown; // TODO: remove shared props here and below after MIMIR-1795
};

const theme: ThemeSchema = {
  textColor: colors.purple,
  backgroundColor: colors.white,
  borderColor: colors.purple,
  iconColor: colors.purple,
  textLinkColor: colors.blue,
  loadingIndicatorColor: colors['light-purple'],
  buttonBorderRadius: pxToRem(24),
  /* Primary Colors */
  primaryButtonBackgroundColor: colors.purple,
  primaryButtonTextColor: colors.white,
  primaryButtonBorderColor: colors.purple,
  // Hover
  primaryButtonHoverBackgroundColor: colors['light-purple'],
  primaryButtonHoverTextColor: colors.white,
  primaryButtonHoverBorderColor: colors['light-purple'],
  // Disabled
  primaryButtonDisabledBackgroundColor: colors['dark-gray'],
  primaryButtonDisabledTextColor: colors['light-gray'],
  primaryButtonDisabledBorderColor: colors['dark-gray'],
  // Borders
  primaryButtonBorderWidth: pxToRem(0),
  primaryButtonPaddingVertical: pxToRem(16),
  primaryButtonPaddingHorizontal: pxToRem(24),
  /* Secondary Colors */
  secondaryButtonBackgroundColor: colors.white,
  secondaryButtonTextColor: colors.purple,
  secondaryButtonBorderColor: colors.purple,
  // Hover
  secondaryButtonHoverBackgroundColor: colors.white,
  secondaryButtonHoverTextColor: colors['light-purple'],
  secondaryButtonHoverBorderColor: colors['light-purple'],
  // Disabled
  secondaryButtonDisabledBackgroundColor: colors['light-gray'],
  secondaryButtonDisabledTextColor: colors['medium-gray'],
  secondaryButtonDisabledBorderColor: colors['medium-gray'],
  // Borders
  secondaryButtonBorderWidth: pxToRem(2),
  /* Tertiary Colors */
  tertiaryButtonBackgroundColor: colors.purple,
  tertiaryButtonTextColor: colors.white,
  tertiaryButtonBorderColor: colors.purple,
  // Hover
  tertiaryButtonHoverBackgroundColor: colors['light-purple'],
  tertiaryButtonHoverTextColor: colors.white,
  tertiaryButtonHoverBorderColor: colors['light-purple'],
  // Disable
  tertiaryButtonDisabledBackgroundColor: colors['medium-gray'],
  tertiaryButtonDisabledBorderColor: colors['medium-gray'],
  tertiaryButtonDisabledTextColor: colors['medium-gray'],
  /* Select Input */
  selectFieldBorderColor: colors['medium-gray'],
  selectFieldActiveBorderColor: colors.purple,
  selectFieldErrorBorderColor: colors['medium-gray'],
};

export const ContextProvider = ({ children, shared }: ContextProviderProps) => (
  <InitializationDataContextProvider>
    <AppContextProvider shared={shared}>
      <Provider.CanvasContextProvider>
        <CardContextProvider>
          <CropContextProvider>
            <CanvasCustomConfig>
              <Theme.ThemeProvider overrides={theme}>{children}</Theme.ThemeProvider>
            </CanvasCustomConfig>
          </CropContextProvider>
        </CardContextProvider>
      </Provider.CanvasContextProvider>
    </AppContextProvider>
  </InitializationDataContextProvider>
);
